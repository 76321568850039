import React from 'react';
import './index.css'
import PartnersBackground from '../../../../Partners_Background/view/index';


function Partners (){
    return (
        <div className = "PartnersBlock">
            <div className="ourPartnersPartText">
                <div className="ourPartnersTextInside">Our Partners</div>
            </div>
        
        </div>

    )
}


export default Partners;