import React from 'react';
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import JoyLangLogo from "../../../assets/images/companiesImages/JoyLangLogo.png";
import SonoRecLogo from "../../../assets/images/companiesImages/SonoRecLogo.png";

function Carrier (){

    return (
        <div className="content">
            <h2 className="pageTitle">Jobs & Carriers</h2>
            <div className="jobs_switch_part">
                <p className="jobs_switch_part_text" onClick={() => {
                        document.getElementsByClassName("jobs_switch_part_switcher")[0].classList.remove("right_jobs_switch_part_switcher");
                        setTimeout(() => {
                            document.getElementsByClassName("carriers_part")[0].classList.add("disactive_list_part");
                            document.getElementsByClassName("jobs_part")[0].classList.remove("disactive_list_part");
                        }, 200)
                    }}>Jobs</p>
                <p className="jobs_switch_part_text" onClick={() => {
                    document.getElementsByClassName("jobs_switch_part_switcher")[0].classList.add("right_jobs_switch_part_switcher");
                    setTimeout(() => {
                        document.getElementsByClassName("jobs_part")[0].classList.add("disactive_list_part");
                        document.getElementsByClassName("carriers_part")[0].classList.remove("disactive_list_part");
                    }, 200)
                }}>Carriers</p>
                <div className="jobs_switch_part_switcher_part">
                    <div className="jobs_switch_part_switcher"></div> 
                </div>
            </div>

            <div className="list_part carriers_part disactive_list_part">
                <div className="list_item">
                    <div className="list_item_logo_part">
                        <img src={JoyLangLogo} className="list_item_logo" />
                    </div>
                    <div className="list_item_text_part">
                        <p className="list_item_text_part_date">
                            <FontAwesomeIcon icon={faCalendarAlt} className= "far fa-calendar-alt deadLineIcon"/>
                            2020.05.01
                        </p>
                        <p className="list_item_text_part_job_title">Android Developer</p>
                        <p className="list_item_text_part_job_short_info">lorem asdasdasdas asdasd asd asd asdasd ipsum</p>
                    </div>
                </div>
                <div className="list_item">
                    <div className="list_item_logo_part">
                        <img src={SonoRecLogo} className="list_item_logo" />
                    </div>
                    <div className="list_item_text_part">
                        <p className="list_item_text_part_date">
                            <FontAwesomeIcon icon={faCalendarAlt} className= "far fa-calendar-alt deadLineIcon"/>
                            2020.02.03
                        </p>
                        <p className="list_item_text_part_job_title">Beat Maker</p>
                        <p className="list_item_text_part_job_short_info">lorem ipsum asdasd asd asdjkaskdb kjasdkjb </p>
                    </div>
                </div>
            
            </div>
            <div className="list_part jobs_part ">
                <div className="jobs_part_item">
                    <div className="jobs_part_item_text_part">
                        <p className="jobs_part_item_text">Android Developer</p>
                        <FontAwesomeIcon icon={faChevronDown} className= "far fa-chevron-down jobs_part_down_button"/>
                    </div>
                    <ul className="jobs_part_item_menu">
                        <li className="jobs_part_item_menu_li">asdasdasd</li>
                        <li className="jobs_part_item_menu_li">asdasdasd</li>
                        <li className="jobs_part_item_menu_li">asdasdasd</li>
                        <li className="jobs_part_item_menu_li">asdasdasd</li>
                    </ul>
                </div>
                
            </div>
        
        </div>
    )
}

export default Carrier;
