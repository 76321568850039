import slider from './slider.json'
import taxi_armenia from './taxi_armenia.json'
import sono_rec from './sono_rec.json'
import joy_land from './joy_land.json'
import royal_oil from './royal_oil.json'


const slidersData = [
    slider,
    sono_rec,
    joy_land,
    taxi_armenia,
    royal_oil,
]

export default slidersData