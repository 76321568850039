import './index.css';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

function Footer(){
    return(
        <div className="footerPart">
            <div className="footerParts">
                <p className="footTextHead">ABOUT US</p>
                <p className="footTextBody1" /*style="font-family: 'Amiri', serif;"*/>WD Holding is the abbreviation of
                    World Dimensions Holding. WD Holding was formed in 2005 in Armenia. It’s a holding that deals
                    with the management of subsidiaries and performs centralized supervision towards its
                    shareholding companies. The company’s founders are Davit Avagyan and Hrayr Demirchyan. Due to
                    the responsibility and dedicated work of the management body of WD Holding, the following
                    achievements were recorded in 2000:<br/>· ISO 9001:2000 - Quality management system
                </p>
            </div>
            <div className="footerParts">
                <p className="footTextHead">LINKS</p>
                <p className="footerElem noselect link-to-open-home" /*onClick = {window.location = "https://wd-holding.com/"}*/>
                    <FontAwesomeIcon icon={faAngleRight} className= "fa fa-angle-right footerLeft"/>Home
                </p>
                <p className="footerElem noselect link-to-open-about-us" /*onClick = {"https://wd-holding.com/views/about_us"}*/>
                    <FontAwesomeIcon icon={faAngleRight} className= "fa fa-angle-right footerLeft"/>About Us
                </p>
                <p className="footerElem noselect link-to-open-our-partners" /*onClick = {"https://wd-holding.com/#ourPartnersPartText"}*/>
                    <FontAwesomeIcon icon={faAngleRight} className= "fa fa-angle-right footerLeft"/>Our Partners
                </p>
                <p className="footerElem noselect link-to-open-news" /*onClick = {"https://wd-holding.com/views/news"}*/>
                    <FontAwesomeIcon icon={faAngleRight} className= "fa fa-angle-right footerLeft"/>News
                </p>
                <p className="footerElem noselect link-to-open-contact" /*onClick = {"https://wd-holding.com/views/contact"}*/>
                    <FontAwesomeIcon icon={faAngleRight} className= "fa fa-angle-right footerLeft"/>Contact
                </p>
                <p className="footerElem noselect link-to-open-carriers" /*onClick = {"https://wd-holding.com/views/carriers"}*/>
                    <FontAwesomeIcon icon={faAngleRight} className= "fa fa-angle-right footerLeft"/>Careers
                </p>
            </div>
            <div className="footerParts">
                <p className="footTextHead">CONTACT</p>
                <div className="footerInfoDiv">
                    <div className="contactPart noselect">
                        <a href=""></a>
                        <img className="contactIcons" src="https://wd-holding.com/images/icons/home.png" alt=""/>
                        <p className="contactText">Sebastia Street, 28 bldg.,
                            Yerevan, Armenia 0065
                        </p>
                    </div>
                    <div className="contactPart noselect">
                        <a className = "telLink" href="tel:+37412440040">
                            <img className="contactIcons footerPhone" src="https://wd-holding.com/images/icons/phone.png" alt=""/>
                            <p className="contactText">+37412440040- Office</p>
                        </a>
                    </div>
                    <div className="contactPart noselect">
                        <a href="mailto:info@wd-holding.com">
                            <img className="contactIcons" src="https://wd-holding.com/images/icons/mail.png" alt=""/>
                            <p className="contactText">info@wd-holding.com</p>
                        </a>
                    </div>
                    
                </div>

            </div>
        </div>
    )
}

export default Footer;