import React from 'react';
import './index.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/owl.carousel.js';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import historicalBackground from "../../../../../assets/images/sliderImages/sliderImage1.jpg";
import taxiArmeniaBackground from "../../../../../assets/images/sliderImages/taxiarmenia.png";
import rightPart1 from "../../../../../assets/images/sliderImages/sliderPart2.png";
import rightPart2 from "../../../../../assets/images/sliderImages/sliderPart3.png";
import leftPart1 from "../../../../../assets/images/sliderImages/sliderPart4.png";
import leftPart2 from "../../../../../assets/images/sliderImages/sliderPart5.png";
import bottomPart1 from "../../../../../assets/images/sliderImages/sliderPart1.png";
import sliderHeader from "../../../../../assets/images/sliderImages/sliderTest.png";

import slidersData from '../../../../../assets/data/sliders_data/sliders_all_get'

function Slider() {
    var Current_Index = 0

    return (
        <div className="sliderBlock">
            <OwlCarousel className="owl-carousel owl-theme" onDragged={(e) => {
                if (e.item.index != Current_Index) {
                    document.getElementsByClassName("slider_text")[0].innerHTML = slidersData[0][e.item.index].text
                    document.getElementsByClassName("slider_title")[0].innerHTML = slidersData[0][e.item.index].title
                    document.getElementsByClassName("shortInfo")[0].innerHTML = slidersData[0][e.item.index].shortInfo
                    
                    console.log(e, slidersData[0][e.item.index]); 
                    Current_Index = e.item.index;
                }
            }} items={1}>
                <div className="sliderPart item il">
                    <img className="img" src={historicalBackground}></img>
                </div>
                <div className="sliderPart item il">
                    <img className="img" src={historicalBackground}></img>
                </div>
                <div className="sliderPart item il">
                    <img className="img" src={historicalBackground}></img>
                </div>
                <div className="sliderPart item il">
                    <img className="img" src={taxiArmeniaBackground}></img>
                </div>
                <div className="sliderPart item il">
                    <img className="img" src={historicalBackground}></img>
                </div>
            </OwlCarousel>
            <div className="comTriangle"></div>
            <div className="rightPart1">
                <img class="rightPart1Image" src={rightPart1}></img>
            </div>
            <div className="rightPart2">
                <img class="rightPart2Image" src={rightPart2}></img>
            </div>
            <div className="leftPart1">
                <img class="leftPart1Image" src={leftPart1}></img>
            </div>
            <div className="leftPart2">
                <img class="leftPart2Image" src={leftPart2}></img>
            </div>
            <div className="bottomPart1">
                <img class="bottomPart1Image" src={bottomPart1}></img>
            </div>
            <div className="textBackground">
                <div className="textPart">
                    <div className="sliderHeader">
                        <h1 className="sliderCompanyName slider_title">Some historical facts</h1>
                        <h3 className="shortInfo">WD Holding founded a number of companies...</h3>
                        <div className="infoSliderCompany">
                            <div className="slider_text">
                                <p>WD Holding founded a number of companies. Those are the following: </p>
                                <p>Company “Wood processing” which was founded in 2000 to deal with wood processing industry. It was the unique production in Armenia manufacturing and processing high quality wood. The abrupt growth of the demand for the mentioned product was the wonderful evidence of its high quality.</p>
                                <p>Small Hydro Power Plant “Kayur HEK” LLC which was founded in 2011 to produce electricity. The first production was launched in 2014.</p>
                                <p>Production of “Gas discharge Lamp Supply sources” which was founded in 2005. In Armenia, similar innovation of scientific-research field was being introduced for the first time. In 2005, the company obtained permission SRA 39152006, 4160-2005 of technical conditions to produce gas discharge lamp supply sources. Afterwards, for the first time in Armenia, the production of those sources was launched. The product was represented and granted as high quality product in some lighting construction projects built for 2014 Olympic games in Sochi.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="readMoreButton">Read More</div>
            <script src="owlcarousel/owl.carousel.min.js"></script>
        </div>
    )
}

export default Slider;