import React from 'react';
import './index.css'
import SonoLogo from '../../../../../assets/images/companiesImages/SonoRecLogo.png';
import JoyLandLogo from '../../../../../assets/images/companiesImages/JoyLangLogo.png';
import TaLogo from '../../../../../assets/images/companiesImages/TA Logo.png';

function Companies (){

    return (
        <div>
            <div className="companies">
                <div className="ComText" >
                    <p className="comTextHeading">Companies</p>
                </div>
                <div className="company"><img className="com_img" index-of-slider="1" src={SonoLogo}/></div>
                <div className="company"><img className="com_img" index-of-slider="2" src={JoyLandLogo}/></div>
                <div className="company"><img className="com_img" index-of-slider="3" src={TaLogo}/></div>
                <div className="company">
                    <svg className="com_img" index-of-slider="4" xmlns="http://www.w3.org/2000/svg" version="1.0" width="150" height="150" viewBox="0 0 4425.000000 973.000000" preserveAspectRatio="xMidYMid meet">
                        <g transform="translate(0.000000,973.000000) scale(0.100000,-0.100000)" fill="red" stroke="none">
                            <path d="M33975 9434 c-450 -33 -748 -82 -1055 -174 -390 -118 -786 -324 -1090 -566 -119 -95 -351 -329 -449 -453 -421 -531 -645 -1143 -723 -1976 -16 -169 -16 -713 0 -885 55 -595 175 -1040 394 -1460 75 -144 240 -400 333 -515 388 -483 836 -793 1415 -980 814 -262 2001 -283 2810 -49 403 116 785 313 1105 570 460 370 813 906 989 1504 169 574 227 1368 150 2030 -116 1000 -529 1768 -1229 2290 -483 360 -1072 567 -1835 645 -145 15 -695 28 -815 19z m580 -1724 c452 -78 794 -363 959 -799 118 -311 166 -709 146 -1225 -19 -513 -92 -881 -225 -1148 -163 -324 -434 -525 -824 -609 -121 -26 -405 -37 -543 -20 -313 37 -567 157 -771 365 -211 215 -339 531 -403 996 -21 157 -31 718 -15 917 52 651 229 1054 576 1309 286 211 677 287 1100 214z"/>
                            <path d="M520 5780 l0 -3500 1120 0 1120 0 0 1401 0 1401 153 -5 c178 -6 243 -20 377 -84 78 -38 109 -60 171 -122 41 -42 110 -128 152 -191 42 -63 219 -380 395 -705 175 -324 453 -839 617 -1142 l298 -553 1255 0 c1190 0 1254 1 1248 17 -8 20 -847 1650 -1008 1958 -111 212 -179 313 -349 525 -146 182 -265 308 -347 371 -65 49 -222 133 -345 184 -37 16 -64 31 -60 35 4 3 51 19 103 34 352 103 625 263 875 513 309 309 485 683 535 1133 18 161 13 450 -10 607 -78 537 -342 970 -760 1249 -278 185 -633 287 -1185 339 -298 29 -737 35 -2547 35 l-1808 0 0 -3500z m3370 2000 c293 -26 456 -84 569 -205 105 -113 145 -230 145 -425 0 -110 -3 -134 -27 -203 -54 -154 -146 -261 -282 -327 -63 -31 -118 -47 -259 -75 -302 -61 -346 -65 -833 -65 l-443 0 0 655 0 655 508 0 c279 0 559 -5 622 -10z"/>
                            <path d="M25440 5775 l0 -3495 1005 0 1005 0 0 3495 0 3495 -1005 0 -1005 0 0 -3495z"/>
                            <path d="M38660 8565 c0 -481 3 -705 10 -705 24 0 273 65 375 97 625 200 1112 561 1556 1153 60 79 109 148 109 152 0 4 -461 8 -1025 8 l-1025 0 0 -705z"/>
                            <path d="M41800 5770 l0 -3500 1010 0 1010 0 0 3500 0 3500 -1010 0 -1010 0 0 -3500z"/>
                            <path d="M9930 7528 c-696 -26 -1285 -229 -1759 -607 -325 -259 -605 -633 -752 -1001 -136 -341 -192 -651 -192 -1055 0 -328 32 -564 114 -830 249 -810 914 -1443 1771 -1685 292 -83 554 -119 928 -127 642 -14 1145 83 1615 312 397 193 730 485 980 860 221 332 353 704 406 1150 20 160 17 567 -4 728 -66 494 -236 907 -528 1280 -83 106 -273 297 -374 377 -554 437 -1278 633 -2205 598z m429 -1424 c103 -25 236 -94 322 -167 173 -148 276 -345 332 -637 20 -104 22 -146 22 -425 -1 -249 -4 -330 -18 -410 -54 -309 -143 -496 -308 -649 -170 -157 -364 -225 -609 -213 -233 12 -396 83 -554 244 -160 162 -243 348 -286 637 -64 431 -16 894 121 1168 72 144 198 281 331 360 180 107 436 143 647 92z"/>
                            <path d="M21425 7469 c-384 -24 -783 -93 -1048 -180 -121 -40 -310 -132 -422 -206 -393 -259 -586 -550 -721 -1087 -19 -76 -34 -139 -32 -141 2 -1 424 -45 938 -99 514 -53 945 -99 956 -101 19 -4 25 6 53 79 96 247 223 381 421 445 216 69 565 90 775 46 89 -19 205 -76 241 -118 61 -73 114 -244 114 -372 l0 -43 -82 -32 c-160 -61 -465 -159 -623 -199 -188 -47 -556 -128 -1105 -241 -429 -89 -556 -119 -729 -175 -532 -172 -837 -423 -997 -820 -125 -311 -137 -744 -28 -1047 122 -338 381 -627 713 -795 211 -107 456 -173 751 -203 163 -17 545 -14 725 5 234 24 222 9 108 129 -148 155 -235 319 -281 524 -27 123 -24 396 6 532 86 384 286 652 802 1069 374 303 548 503 675 774 28 58 52 107 55 107 3 0 3 -69 2 -152 -3 -126 0 -171 18 -258 39 -193 85 -306 277 -690 202 -402 241 -489 283 -631 85 -286 77 -560 -25 -836 -45 -120 -95 -214 -190 -359 l-72 -111 591 -6 c325 -4 705 -7 844 -7 l252 0 0 1708 c0 1908 2 1846 -70 2128 -93 366 -219 607 -424 810 -371 368 -823 508 -1781 554 -242 11 -780 11 -970 -1z"/>
                            <path d="M13106 7098 c63 -150 540 -1286 1061 -2525 l945 -2252 -49 -114 c-182 -419 -418 -560 -908 -543 -289 10 -542 48 -766 115 -56 17 -103 30 -105 28 -3 -3 158 -1431 162 -1436 12 -12 749 -92 1029 -112 306 -21 687 -3 948 46 442 83 788 267 1016 539 190 226 361 546 541 1011 47 121 1988 5344 2036 5478 l13 37 -996 -2 -995 -3 -458 -1593 c-252 -876 -460 -1596 -463 -1598 -3 -3 -8 3 -11 13 -28 93 -952 3073 -967 3121 l-21 62 -1063 0 -1063 0 114 -272z"/>
                            <path d="M38660 4820 l0 -2550 1005 0 1005 0 0 2550 0 2550 -1005 0 -1005 0 0 -2550z"/>
                            <path d="M22690 4455 c0 -117 -28 -576 -45 -755 -74 -737 -253 -1195 -558 -1426 -34 -26 -89 -60 -122 -76 l-60 -30 125 7 c587 30 944 275 1067 730 26 94 27 112 27 320 0 187 -3 234 -21 315 -29 129 -85 299 -143 436 -55 128 -248 534 -261 549 -5 5 -9 -26 -9 -70z"/>
                        </g>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default Companies;
