import React from 'react';
import './index.css'
import Search from '../../Search/view/index';


function Header (){
    return (
        <div className = "headerBlock">
            <div className="headerDesignePart">
                <div className="designTriangle1"></div>
                <div className="designTriangle2"></div>
                <div className="designTriangle3"></div>
                <div className="shadowTriangle1"></div>
                <div className="shadowTriangle2"></div>
                <div className="shadowTriangle3"></div>
            </div>
            <div class="linesParts">
                <div className="blueLines1"></div>
                <div className="blueLines2"></div>
                <div className="blueLines3"></div>
                <div className="blueLines4"></div>
            </div>
            <div className="right_part">
                <div className="rightShadowTriangle1"></div>
                <div className="rightShadowTriangle2"></div>
                <div className="rightBlueLines1"></div>
                <div className="rightBlueLines2"></div>
                <div className="rightTriangle1"></div>
                <div className="rightTriangle2"></div>
            </div>
            <div className = "dotes"></div>
            <div className="headerContentPart">
                <p className="whatIs">
                    <span className="companyName">What Is</span>
                    <span className="companyName">WD Holding</span> 
                </p>
                <p className="aboutCompany">
                    <span className="companyText">WD Holding is the abbreviation of World Dimensions Holding.</span>
                    <span className="companyText">WD Holding was formed in 2005 in Armenia</span>
                    <Search/>
                </p>
            </div>
        </div>
    )
}

export default Header;