import React from 'react';
import './index.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';



function Search (){

    return (
        <div className="searchPart">
        
            <input type="text" className= "search"/>
            
        </div>
    )
}

export default Search;