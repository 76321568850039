
import React from 'react';
import './index.css'
import partnersBackgroundImage from '../../../assets/images/partnersBackgropund.png';


function PartnersBackground (){

    return (
        <div className="ourPartnersPart">
            <img className = "partnersBackgroundImage" src = {partnersBackgroundImage}></img>
        </div>
    )
}

export default PartnersBackground;










