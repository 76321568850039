import './index.css';  
import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';


function Menu() {

    let [isOpen, setIsOpen] = useState(false);
    let [isClose, setIsClose] = useState(false);

    setIsOpen = () => {
        document.getElementsByClassName("openMenuNav")[0].style.display = ("inline-block");
        document.getElementsByClassName("menuToOpen")[0].style.display = ("inline-block");
        document.getElementsByClassName("openMenuNav")[0].style.animationName = ("fromRightToLeft");
        document.body.style.overflowY = ("hidden");
        document.body.parentElement.style.overflowY = ("hidden")
    }

    setIsClose = () =>{
        document.getElementsByClassName('menuToOpen')[0].style.display = "none";
        document.getElementsByClassName("openMenuNav")[0].style.animationName = ("fromLeftToDeepRight");
        setTimeout(() => {document.getElementsByClassName('openMenuNav')[0].style.display = "none"},200)
        document.body.style.overflowY = "auto";
        document.body.parentElement.style.overflowY = "auto";
    }

    const changeLocation = (hrefClass) => {
        document.getElementsByClassName(hrefClass)[0].click()
    }

   
    return(
        <div className = "menuBlock">
            <div className="openMenuNav">
                <div className="menuBrdDiv">
                    <div className = "menuNavBar">
                        <p className = "close-block closeBlock" onClick = {() => setIsClose((isClose) => !isClose)}>
                            <FontAwesomeIcon icon={faAngleDoubleRight} className= "closeBlockIcon"/>
                        </p>
                    </div>
                    <div className="openMenuItem noselect menu-home-small" onClick={() => {changeLocation("menu-home")}}>Home
                        <i className="fa fa-angle-right"></i>
                    </div>
                    <div className="openMenuItem noselect menu-about-us-small" onClick={() => {changeLocation("menu-about-us")}}>About Us
                        <i className="fa fa-angle-right"></i>
                    </div>
                    <div className="openMenuItem noselect menu-our-partners-small" onClick={() => {changeLocation("menu-our-partners")}}>Our Partners
                        <i className="fa fa-angle-right"></i>
                    </div>
                    <div className="openMenuItem noselect menu-news-small" onClick={() => {changeLocation("menu-news")}}>News
                        <i className="fa fa-angle-right"></i>
                    </div>
                    <div className="openMenuItem noselect menu-contact-small" onClick={() => {changeLocation("menu-contact")}}>Contact
                        <i className="fa fa-angle-right"></i>
                    </div>
                    <div className="openMenuItem noselect menu-carriers-small" onClick={() => {changeLocation("menu-carriers")}}>Careers
                        <i className="fa fa-angle-right"></i>
                    </div>
                </div>
            </div>
            <div className="menuToOpen" onClick = {() => setIsClose((isClose) => !isClose)}></div>
            <div className="navbar">
                <div className="menu">
                    <div className="menuItem">
                        <a className="menuItemText noselect menu-home" href="/">Home</a>
                    </div>
                    <div className="menuItem">
                        <a className="menuItemText noselect menu-about-us" href="/about">About Us</a>
                    </div>
                    <div className="menuItem">
                        <a className="menuItemText noselect menu-our-partners" href="/">Our Partners</a>
                    </div>
                    <div className="menuItem">
                        <a className="menuItemText noselect menu-news" href="/news">News</a>
                    </div>
                    <div className="menuItem">
                        <a className="menuItemText noselect menu-contact" href="contact">Contact</a>
                    </div>
                    <div className="menuItem">
                        <a className="menuItemText noselect menu-carriers" href="/carriers">Careers</a>
                    </div>
                    <div className = "openSmallMenu openSmallMenuClick" onClick = {() => setIsOpen((isOpen) => !isOpen)} >
                        <FontAwesomeIcon icon={faBars} className= "menuItemText fa fa-navicon " />
                    </div>
                </div> 
                <div className="logopart"> 
                    <a className = "logoLink" href = "https://wd-holding.com">
                        <img src="https://wd-holding.com/images/wd_logo.png" className="logoImg noselect" alt="#" />
                    </a>    
                </div>
            </div>    
        </div>
    );
}

export default Menu;
