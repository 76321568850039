import React from 'react';
import './index.css'


function AboutUs (){

    return (
        <div className="content">
            <h1 className="page-title pageTitle">About Us</h1>
                <p className="paragraph">WD Holding is the abbreviation of World Dimensions Holding. WD Holding was formed in 2005 in Armenia. It’s a holding that deals with the management of subsidiaries and performs centralized supervision towards its shareholding companies.</p>
                <p className="paragraph">The company’s founders are Davit Avagyan and Hrayr Demirchyan. Due to the responsibility and dedicated work of the management body of WD Holding, the following achievements were recorded in 2000:</p>
                <li className="paragraphDot">ISO 9001:2000 - Quality management system</li>
                <h1 className="page-title">Some historical facts</h1>
                <p className="paragraph">WD Holding founded a number of companies. Those are the following:</p>
                <p className="paragraph">Company “Wood processing” which was founded in 2000 to deal with wood processing industry. It was the unique production in Armenia manufacturing and processing high quality wood. The abrupt growth of the demand for the mentioned product was the wonderful evidence of its high quality.</p>
                <p className="paragraph">Small Hydro Power Plant “Kayur HEK” LLC which was founded in 2011 to produce electricity. The first production was launched in 2014.</p>
                <p className="paragraph">Production of “Gas discharge Lamp Supply sources” which was founded in 2005. In Armenia, similar innovation of scientific-research field was being introduced for the first time. In 2005, the company obtained permission SRA 39152006, 4160-2005 of technical conditions to produce gas discharge lamp supply sources. Afterwards, for the first time in Armenia, the production of those sources was launched. The product was represented and granted as high quality product in some lighting construction projects built for 2014 Olympic games in Sochi.</p>
            <h1 className="page-title">Companies</h1>
                <p className="paragraph">“SONO REC” Studio which was founded in 2005 dealing with recording and film production. “SONO REC” Studio is equipped with modern innovating technologies of sound isolation and high quality equipments which ensures contemporary approaches of recording and sound processing both for local and international market. The Studio has the electronic device SSL- Solid State Logic studio console which is the unique one in Armenia.</p>
                <br/>
                <p className="paragraph">“JOY LAND” company which was founded in 2008 dealing with creation of 2D animation films. “JOY LAND” is also an entertainment center for kids. The first animation film titled “Floating island” was launched in 2016 by “JOY LAND” company which later became one of the most required cartoons in Armenian language.</p>
                <br/>
                <p className="paragraph">“TAXI ARMENIA” which was founded in 2016. It is an online operator for taxi services offering its service both to passengers and drivers. With it creation and modern approaches, Taxi Armenia introduced innovations in the field of service, including the online registration of drivers via the application TAXI ARMENIA DRIVER.</p>
                <br/>
                <p className="paragraph">“ROYAL OIL” LLC which was founded in 2019 to import fuel into Armenia for retail and wholesale trade. “ROYAL OIL” LLC provides high quality fuel supply. It has a particular approach towards its clients in all the sale points. In the international market the company has obtained great confidence as a result of which the importation of the famous brand to Armenia has been permitted.</p>
                <br/>
                <p className="paragraph">“Kik Obzor” YouTube channel which was founded in 2014. The motivation source for this channel are Indiegogo and Kickstarter innovative platforms which serves to take the most interesting and attractive information and present it to Russian speaking market as a review.</p>
        </div>
    )
}

export default AboutUs;