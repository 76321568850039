import React from 'react';
import './index.css'

import Header from '../../.././components/Home/utilities/Header/view/index';
import Companies from '../../.././components/Home/utilities/Companies/view/index';
import Slider from '../../.././components/Home/utilities/Slider/view/index';
import Partners from '../../Home/utilities/Partners/view/index'
import PartnersBackground from '../../Partners_Background/view/index'

function Home (){

    return (
        <div>
            <Header/>
            <Companies/>
            <Slider/>
            <Partners/>
            <PartnersBackground/>
            
        </div>
    )
}

export default Home;
