import React, {useEffect} from 'react';
import './index.css'

// import news_image from "../../../assets/images/news_image.jpeg";
import left from "../../../assets/images/sliderImages/left.png";
import right from "../../../assets/images/sliderImages/right.png";


function News (){

    var SliderPageIndex = 1

    var NewsItemsDefaultCount = 0

    var AllIndex = 1

    useEffect(() => {
        NewsSliderConfig()
        window.onresize = () => {
            NewsSliderConfig()
        }
    }, [])

    const NewsSliderConfig = () => {
        var newsItemsCount = document.getElementsByClassName('news_item')
        var lastSliderPageIndex = NewsItemsDefaultCount
        if (window.innerWidth <= 750) {
            NewsItemsDefaultCount = 1
        }else if(window.innerWidth <= 1286){
            NewsItemsDefaultCount = 4
        }else {
            NewsItemsDefaultCount = 6
        }
        SliderPageIndex = 1

        if (NewsItemsDefaultCount != lastSliderPageIndex){
            for (let i = 0; i < newsItemsCount.length; i++) {
                newsItemsCount[i].style.display = "none"
            }
            AllIndex = (newsItemsCount.length%NewsItemsDefaultCount)==0?newsItemsCount.length/NewsItemsDefaultCount:parseInt(newsItemsCount.length/NewsItemsDefaultCount)+1
            if (newsItemsCount.length > NewsItemsDefaultCount) {
                document.getElementsByClassName("rightArrow")[0].style.display = "inline-block"
                document.getElementsByClassName("leftArrow")[0].style.display = "none"
                for (let i = (SliderPageIndex-1)*NewsItemsDefaultCount; i < SliderPageIndex*NewsItemsDefaultCount; i++) {
                    newsItemsCount[i].style.display = "flex"
                }
            }
        }
    }

    const arrowClick = (arrowDirection) => {
        var newsItemsCount = document.getElementsByClassName('news_item')
        switch (arrowDirection) {
            case "right":
                if(SliderPageIndex<=AllIndex){
                    document.getElementsByClassName("leftArrow")[0].style.display = "inline-block"
                    for (let i = (SliderPageIndex-1)*NewsItemsDefaultCount; i < (SliderPageIndex)*NewsItemsDefaultCount; i++) {
                        newsItemsCount[i].style.display = "none"
                    }
                    for (let i = (SliderPageIndex)*NewsItemsDefaultCount; i < (SliderPageIndex+1)*NewsItemsDefaultCount; i++) {
                        if(i<newsItemsCount.length){
                            newsItemsCount[i].style.display = "flex"
                        }
                    }
                    SliderPageIndex++
                    if(SliderPageIndex==AllIndex){
                        document.getElementsByClassName("rightArrow")[0].style.display = "none"
                    }
                }else{
                    document.getElementsByClassName("rightArrow")[0].style.display = "none"
                }
                break;

            case "left":
                if(SliderPageIndex>0){
                    document.getElementsByClassName("rightArrow")[0].style.display = "inline-block"
                    for (let i = (SliderPageIndex-1)*NewsItemsDefaultCount; i < (SliderPageIndex)*NewsItemsDefaultCount; i++) {
                        if(newsItemsCount[i]){
                            newsItemsCount[i].style.display = "none"       
                        }else{
                            break;
                        }
                    }
                    for (let i = (SliderPageIndex-2)*NewsItemsDefaultCount; i < (SliderPageIndex-1)*NewsItemsDefaultCount; i++) {
                        if(i<newsItemsCount.length){
                            newsItemsCount[i].style.display = "flex"
                        }
                    }
                    SliderPageIndex--
                    if(SliderPageIndex==1){
                        document.getElementsByClassName("leftArrow")[0].style.display = "none"
                    }
                }else{
                    document.getElementsByClassName("leftArrow")[0].style.display = "none"
                }
                break;
        
            default:
                break;
        }
    }

    return (
        <div>
            <div className="content">
                <h2 className="pageTitle">News & Updates</h2>
                <div className="news_collaction">
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">3 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">4 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">5 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">6 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">7 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">8 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                    <div className="news_item">
                        <div className="news_item_image_part"></div>
                        <div className="news_item_text_part">
                            <p className="news_item_text">9 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et eaque, ex ratione veniam cum, non inventore pariatur dignissimos similique sint atque quod incidunt cumque natus expedita, animi quia fuga.</p>
                            <div className="news_item_read_more_button">read more</div>
                        </div>

                    </div>
                </div>
                <img src={left} alt="" className="newsArrows leftArrow" onClick={() => {arrowClick("left")}}/>
                <img src={right} alt="" className="newsArrows rightArrow" onClick={() => {arrowClick("right")}}/>
            </div>
        </div>
    )
}

export default News;
