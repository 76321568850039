import React, {useState} from 'react';
import './index.css'

function Contact (){

    return (
        <div className="content">
          <div id="this_is_contact" style={{display: "none"}}></div>
          <h2 className="pageTitle">Contact</h2>
          <div id="map" className="loc_map"></div>
        </div>
    )
}

export default Contact;
