import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './assets/css/main.css'
// Pages
import Home from './components/Home/view/index';
import News from './components/News/view/index'
import AboutUs from './components/AboutUs/view/index'
import Carrier from './components/Carriers/view/index' 
import Contact from './components/Contact/view/index';

//utilities
import Menu from './components/utilities/Menu/view/index';
import Footer from './components/utilities/Footer/view/index';


ReactDOM.render(
  <Router>
    <Menu/> 

    <Switch>
      <Route path="/" exact component={Home}/>
      <Route path="/news" exact component={News}/>
      <Route path="/about" exact component={AboutUs}/>
      <Route path="/carriers" exact component={Carrier}/>
      <Route path="/contact" exact component={Contact}/>
      <Route path="/" component={Home}/>
    </Switch>

    <Footer/>
    
    
    
  </Router>,
  document.getElementById('root')
);


reportWebVitals();
